// Libraries
import React, { PureComponent } from 'react';

// Utils & Services
import { CustomScrollbar, stylesFactory } from '@grafana/ui';

import config from 'app/core/config';
import { feedToDataFrame } from './utils';
import { loadRSSFeed } from './rss';

// Types
import { DataFrameView, GrafanaTheme, PanelProps, textUtil } from '@grafana/data';
import { IntegrationItem, IntegrationOptions } from './types';
import { DEFAULT_FEED_URL, PROXY_PREFIX } from './constants';
import { css } from '@emotion/css';

interface Props extends PanelProps<IntegrationOptions> {}

interface State {
  integration?: DataFrameView<IntegrationItem>;
  isError?: boolean;
}

export class IntegrationPanel extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    this.loadChannel();
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.options.feedUrl !== prevProps.options.feedUrl) {
      this.loadChannel();
    }
  }

  async loadChannel() {
    const { options } = this.props;
    try {
      const url = options.feedUrl
        ? options.useProxy
          ? `${PROXY_PREFIX}${options.feedUrl}`
          : options.feedUrl
        : DEFAULT_FEED_URL;
      const res = await loadRSSFeed(url);
      const frame = feedToDataFrame(res);
      this.setState({
        integration: new DataFrameView<IntegrationItem>(frame),
        isError: false,
      });
    } catch (err) {
      console.error('Error Loading Integration', err);
      this.setState({
        integration: undefined,
        isError: true,
      });
    }
  }

  render() {
    const { isError, integration } = this.state;
    const styles = getStyles(config.theme);

    if (isError) {
      return <div>Error Loading Integration</div>;
    }
    if (!integration) {
      return <div>loading...</div>;
    }

    return (
      <CustomScrollbar autoHeightMin="100%" autoHeightMax="100%">
        <div className={styles.panel}>
          {integration.map((item, index) => {
            if (item.title === 'Grafana服务共享版') {
              return (
                <div key={index} className={styles.item}>
                  <img src={item.ogImage} className={styles.image} />
                  <div className={styles.title}>{item.title}</div>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a className={styles.link} href={textUtil.sanitizeUrl(item.link)} target="_blank">
                    去迁移
                  </a>
                </div>
              );
            }

            if (item.dash === '') {
              return (
                <div key={index} className={styles.item}>
                  <img src={item.ogImage} className={styles.image} />
                  <div className={styles.title}>{item.title}</div>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a className={styles.link} href={textUtil.sanitizeUrl(item.link)} target="_blank">
                    去集成
                  </a>
                  <div className={styles.count}>大盘模板:{item.count}</div>
                </div>
              );
            }
            return (
              <div key={index} className={styles.item}>
                <img src={item.ogImage} className={styles.image} />
                <div className={styles.title}>{item.title}</div>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a className={styles.link} href={textUtil.sanitizeUrl(item.link)} target="_blank">
                  去集成
                </a>
                <a className={styles.link} href={textUtil.sanitizeUrl(item.dash)} target="_self">
                  查看
                </a>
                <div className={styles.count}>大盘模板:{item.count}</div>
              </div>
            );
          })}
        </div>
      </CustomScrollbar>
    );
  }
}

const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  panel: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
  `,
  container: css`
    height: 100%;
  `,
  image: css`
    margin-left: 6px;
    height: 24px;
    width: 24px;
  `,
  item: css`
    padding: ${theme.spacing.sm};
    position: relative;
    margin-bottom: 4px;
    width: 30%;
    display: flex;
    margin-right: ${theme.spacing.sm};
    border-bottom: 2px solid ${theme.colors.border1};
  `,
  link: css`
    margin-left: 6px;
    color: ${theme.colors.linkExternal};
    &:hover {
      color: ${theme.colors.linkExternal};
      text-decoration: underline;
    }
  `,
  title: css`
    max-width: 200px;
    font-size: 14px;
    margin-left: 6px;
    margin-bottom: ${theme.spacing.sm};
  `,
  content: css`
    p {
      margin-bottom: 4px;
      color: ${theme.colors.text};
    }
  `,
  count: css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${theme.colors.panelBg};
    width: 80px;
    text-align: right;
    padding: ${theme.spacing.xs};
    font-weight: 400;
    font-size: 8px;
    margin-right: 20px;
    border-radius: 0 0 0 3px;
    color: ${theme.colors.textWeak};
  `,
}));
